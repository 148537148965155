<template>
    <div id="accessErrorPortlet" class="kt-content kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                    <div class="kt-portlet__body kt-block-center kt-align-center" id="emptylist_widget">
                        <div class="kt-widget">
                            <div class="kt-widget__head">
                                <div class="kt-widget__media">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" id="Path-117" fill="#000000" opacity="0.3" />
                                            <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1" />
                                            <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </div>
                                <p id="incompleteConfigTopLabel">{{ $t("error_accessDeniedTitle") }}</p>
                                <p v-if="hasFullConfigAccess(siteId)" id="incompleteConfigSubLabel">
                                    <span> {{ $t("error_accessDeniedSubTitle") }} :</span>
                                    <br />
                                    <!-- <a href="#" v-if="noSiteLocationError" class="kt-link" @click="onLinkClicked('siteLocation')">- {{ $t("error_accessDeniedSiteLocation") }}</a> -->
                                    <router-link v-if="noSiteLocationError" class="kt-link" :to="{ name: 'siteLocation' }" >- {{ $t("error_accessDeniedSiteLocation") }}</router-link>
                                    <br v-if="noSiteLocationError" />
                                    <!-- <a href="#" v-if="noBuildingCreatedError" class="kt-link" @click="onLinkClicked('buildings')">- {{ $t("error_accessDeniedMissingBuilding") }}</a> -->
                                    <router-link v-if="noBuildingCreatedError" class="kt-link" :to="{ name: 'buildings' }" >- {{ $t("error_accessDeniedMissingBuilding") }}</router-link>
                                    <br v-if="noBuildingCreatedError" />
                                    <!-- <a href="#" v-if="noFloorCreatedError" class="kt-link" @click="onLinkClicked('buildings')">- {{ $t("error_accessDeniedMissingFloor") }}</a> -->
                                    <router-link v-if="noFloorCreatedError" class="kt-link" :to="{ name: 'buildings' }" >- {{ $t("error_accessDeniedMissingFloor") }}</router-link>
                                    <br v-if="noFloorCreatedError" />
                                    <!-- <a href="#" v-if="noIndoorAreaCreatedError" class="kt-link" @click="onLinkClicked('indoorAreas')">- {{ $t("error_accessDeniedMissingIndoorArea") }}</a> -->
                                    <router-link v-if="noIndoorAreaCreatedError" class="kt-link" :to="{ name: 'indoorAreas' }" >- {{ $t("error_accessDeniedMissingIndoorArea") }}</router-link>
                                    <br v-if="noIndoorAreaCreatedError" />
                                    <!-- <a href="#" v-if="noGatewayCreatedError" class="kt-link" @click="onLinkClicked('gateways')">- {{ $t("error_accessDeniedMissingGateway") }}</a> -->
                                    <router-link v-if="noGatewayCreatedError" class="kt-link" :to="{ name: 'gateways' }" >- {{ $t("error_accessDeniedMissingGateway") }}</router-link>
                                    <br v-if="noGatewayCreatedError" />
                                    <!-- <a href="#" v-if="noBLETagCreatedError" class="kt-link" @click="onLinkClicked('tags')">- {{ $t("error_accessDeniedMissingBLETag") }}</a> -->
                                    <router-link v-if="noBLETagCreatedError" class="kt-link" :to="{ name: 'tags' }" >- {{ $t("error_accessDeniedMissingBLETag") }}</router-link>
                                    <br v-if="noBLETagCreatedError" />
                                    <!-- <a href="#" v-if="noAssetCreatedError" class="kt-link" @click="onLinkClicked('assets')">- {{ $t("error_accessDeniedMissingAsset") }}</a> -->
                                    <router-link v-if="noAssetCreatedError" class="kt-link" :to="{ name: 'assets' }" >- {{ $t("error_accessDeniedMissingAsset") }}</router-link>
                                    <br v-if="noAssetCreatedError" />
                                    <a href="#" v-if="noAssetCategoryCreatedError" class="kt-link" @click="onLinkClicked('assetCategories')">- {{ $t("error_accessDeniedMissingAssetCategory") }}</a>
                                    <router-link v-if="noAssetCategoryCreatedError" class="kt-link" :to="{ name: 'assetCategories' }" >- {{ $t("error_accessDeniedMissingAssetCategory") }}</router-link>
                                    <br v-if="noAssetCategoryCreatedError" />
                                    <!-- <a href="#" v-if="noGeofenceCreatedError" class="kt-link" @click="onLinkClicked('geofences')">- {{ $t("error_accessDeniedMissingGeofence") }}</a> -->
                                    <router-link v-if="noGeofenceCreatedError" class="kt-link" :to="{ name: 'geofences' }" >- {{ $t("error_accessDeniedMissingGeofence") }}</router-link>
                                    <br v-if="noGeofenceCreatedError" />
                                </p>
                                <p v-else id="nonAdminIncompleteConfigSubLabel">
                                    <span> {{ $t("error_nonAdminAccessDeniedSubTitle") }} </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        activeItem: String,
        siteId: String
    },
    data() {
        console.log("Component(AccessErrorPortlet)::data() - called");
        return {};
    },
    created: function() {
        console.log("Component(AccessErrorPortlet)::created() - called");
        if (this.activeItem === "indoorAreas" || this.activeItem === "geofences" || this.activeItem === "walkways" || this.activeItem === "walls" || this.activeItem === "autocalibrationTagsLocation" || this.activeItem === "gatewaysLocation" || this.activeItem === "assetTrackingDashboard") {
            // To check conditions on minimum one building + one floor created (siteBuildings and totalNumberOfFloors getters)
            this.getSiteBuildingsAndTheirFloors(this.siteId);
        }
        if (this.activeItem === "assetTrackingDashboard") {
            // To check conditions on minimum one indoor area defined
            this.getSiteIndoorAreas(this.siteId);
        }
        if (this.activeItem === "assetTrackingDashboard") {
            // To check conditions on minimum one gateway created AND located
            this.getSiteBLEGateways(this.siteId);
        }
        if (this.activeItem === "assets" || this.activeItem === "assetTrackingDashboard") {
            // To check conditions on minimum one BLE tag
            this.getSiteTags(this.siteId);
        }
        if (this.activeItem === "assetTrackingDashboard") {
            // To check conditions on minimum one asset
            this.getSiteAssets(this.siteId);
        }
        if (this.activeItem === "assets") {
            // To check conditions on minimum one assetCategory
            this.getSiteAssetCategories(this.siteId);
        }
        if (this.activeItem === "geonotifications") {
            // To check conditions on minimum one geofence
            this.getSiteGeofences(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(AccessErrorPortlet)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(AccessErrorPortlet)::destroyed() - called");
    },
    beforeRouteLeave(to, from, next) {
        this.resetBuildingsState();
        this.resetIndoorAreasState();
        this.resetGatewaysState();
        this.resetTagsState();
        this.resetAssetsState();
        this.resetCategoriesState();
        this.resetGeofencesState();
        next();
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "siteBuildings", "totalNumberOfFloors", "siteIndoorAreas", "siteBLEGateways", "siteTags", "siteAssets", "siteAssetCategories", "hasFullConfigAccess", "siteGeofences"]),

        noSiteLocationError: function() {
            return (
                (this.activeItem === "buildings" || this.activeItem === "indoorAreas" || this.activeItem === "geofences" || this.activeItem === "walkways" || this.activeItem === "walls" || this.activeItem === "autocalibrationTagsLocation" || this.activeItem === "gatewaysLocation" || this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") &&
                this.currentSite !== null && // site is loaded
                this.currentSite.location === null // Site location has not been defined
            );
        },

        noBuildingCreatedError: function() {
            return (
                (this.activeItem === "indoorAreas" || this.activeItem === "geofences" || this.activeItem === "walkways" || this.activeItem === "walls" || this.activeItem === "autocalibrationTagsLocation" || this.activeItem === "gatewaysLocation" || this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") &&
                this.siteBuildings !== null && // return no error if buildings are not yet loaded
                this.siteBuildings.length === 0
            );
        },

        noFloorCreatedError: function() {
            return (
                (this.activeItem === "indoorAreas" || this.activeItem === "geofences" || this.activeItem === "walkways" || this.activeItem === "walls" || this.activeItem === "autocalibrationTagsLocation" || this.activeItem === "gatewaysLocation" || this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") &&
                this.siteBuildings !== null && // return no error if buildings are not yet loaded
                this.totalNumberOfFloors !== null && // return no error if floors are not yet loaded
                this.totalNumberOfFloors === 0
            );
        },

        noIndoorAreaCreatedError: function() {
            return (
                (this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") &&
                this.siteIndoorAreas !== null && // return no error if indoor areas are not yet loaded
                this.siteIndoorAreas.length === 0
            );
        },

        noGatewayCreatedError: function() {
            if ((this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") && this.siteBLEGateways !== null) {
                // return no error if BLE gateways are not yet loaded
                if (this.siteBLEGateways.length === 0) {
                    // No gateway created, display error
                    return true;
                }
                for (let item in this.siteBLEGateways) {
                    if (this.siteBLEGateways[item].location && this.siteBLEGateways[item].location.type) {
                        return false;
                    }
                }
                return true; // No located gateway has been found, raise error
            } else return false;
        },

        noBLETagCreatedError: function() {
            return (
                (this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") &&
                this.siteTags !== null && // return no error if tags are not yet loaded
                this.siteTags.length === 0
            );
        },

        noAssetCreatedError: function() {
            if ((this.activeItem === "assetTrackingDashboard" || this.activeItem === "prodModeSwitch") && this.siteAssets !== null) {
                // return no error if assets are not yet loaded
                if (this.siteAssets.length === 0) {
                    // No assets created, display error
                    return true;
                }
                for (let item in this.siteAssets) {
                    if (this.siteAssets[item].tag) {
                        // There is at least one existing asset with an associated tag
                        return false;
                    }
                }
                return true; // No located gateway has been found, raise error
            } else return false;
        },

        noAssetCategoryCreatedError: function() {
            return (
                this.activeItem === "assets" &&
                this.siteAssetCategories !== null && // return no error if assetCategories are not yet loaded
                this.siteAssetCategories.length === 0
            );
        },

        noGeofenceCreatedError: function() {
            // Return no error if a site has a geofence
            return (this.activeItem === "geonotifications" && this.siteGeofences !== null && this.siteGeofences.length === 0);
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteBuildingsAndTheirFloors", "getSiteIndoorAreas", "getSiteBLEGateways", "getSiteTags", "getSiteAssets", "getSiteAssetCategories", "getSiteGeofences", "resetBuildingsState", "resetIndoorAreasState", "resetGatewaysState", "resetTagsState", "resetAssetsState", "resetCategoriesState", "resetGeofencesState"]),

        isAccessGranted: function() {
            console.log("Component(AccessErrorPortlet)::isAccessGranted() - called");
            return !this.noSiteLocationError && !this.noBuildingCreatedError && !this.noFloorCreatedError && !this.noIndoorAreaCreatedError && !this.noGatewayCreatedError && !this.noBLETagCreatedError && !this.noAssetCreatedError && !this.noAssetCategoryCreatedError && !this.noGeofenceCreatedError;
        },

        onLinkClicked: function(route) {
            // Route to requested page
            this.$router.push({ name: route });
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
#incompleteConfigTopLabel {
    margin-top: 25px;
    font-weight: 400;
    font-size: 15px;
    color: black;
}
#incompleteConfigSubLabel {
    font-weight: 400;
    font-size: 12px;
}
#incompleteConfigSubLabel span {
    line-height: 22px;
}
.kt-svg-icon {
    width: 100px !important;
    height: 100px !important;
}
</style>
